import * as React from 'react'
import { graphql } from 'gatsby'
import { DefaultLayout } from '../layouts/main-layout'

import '../styles/blogpost.css'

interface FrontMatterData {
  author: string
  date: string
  title: string
}

interface MarkdownRemark {
  frontmatter: FrontMatterData
  html: string
}

interface MarkdownData {
  markdownRemark: MarkdownRemark
}

interface TemplateProps {
  data: MarkdownData
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}: TemplateProps) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  console.log(html)
  return (
    <DefaultLayout
      name="Insights"
      description="BridgePhase, LLC: Where Innovation and Performance Converge."
    >
      <div className="content">
        <div className="frontmatter">
          <h2>{frontmatter.title}</h2>
          <h1>By: {frontmatter.author}</h1>
          <h1>Published: {frontmatter.date}</h1>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </DefaultLayout>
  )
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        author
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
